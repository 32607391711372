import { useEffect, useState } from "react";
import Breadcrum from "../../commons/Breadcrum";

import "../ProjectManagement.css";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import PrimaryTable from "../../primaryTable/PrimaryTable";
import * as ProjectSchema from "../ProjectManagementSchema";
import DeleteModal from "../../Common/DeleteModal";
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Row,
  Modal,
  ModalBody,
  ModalHeader,
  DropdownToggle,
  DropdownItem,
  DropdownMenu,
  Button,
} from "reactstrap";
import * as globalApi from "../../../api/global.api";
import Loader from "../../CommonComponent/Loader";
import { Toast } from "primereact/toast";
import { GetMonths, ProjectCategory, addToast } from "../../commons/common";
import { errorMsg } from "../../commons/constant";
import { frequenciesArr } from "../../commons/constant";
import { Dialog } from "primereact/dialog";
import UnAuthDialog from "../../commons/UnAuthDialog";
import { Tooltip } from "primereact/tooltip";
import { projectTsx } from "../../../store/projectreducer";
import { useAppDispatch } from "../../../store/hooks";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Pagination from "../../commons/pagination";
import BasicDataTable from "../../commons/BasicDataTable";
import BreadCrumb from "../../Common/BreadCrumb";
import { useSelector } from "react-redux";

const Projects = () => {
  let navigate = useNavigate();
  const location = useLocation()?.state;
  const prevRoute: any = sessionStorage.getItem("prevRoute");
  const [spinner, setSpinner] = useState(true);
  const [columns, setColumns] = useState<any>(
    ProjectSchema.Projects.ProjectsList
  );
  const [pageRows, setPageRows] = useState(10);
  const [searchFieldKey, setSearchFieldKey] = useState<any>({});
  const [totalRecords, setTotalRecords] = useState<number>(0);
  const [first, setFirst] = useState<number>(0);
  const [rows, setRows] = useState<any>([]);
  const [data, setData] = useState<any>({});
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [viewModal, setViewModal] = useState(false);
  const [row, setRow] = useState<any>({});
  const [frequencies, setfrequncies] = useState(frequenciesArr);
  const [clients, setClients] = useState<any>([]);
  const [showLogout, setShowLogout] = useState<any>(false);
  const [clientName, setClientName] = useState<any>();

  const id = useParams().id;

  const dispatch = useAppDispatch();
  useEffect(() => {
    getProjects(`page=0&limit=10`);
    dispatch(projectTsx({}));
  }, []);

  const handleDelete = (rowData: any, column: any) => {
    setData(rowData);
    setShowDeleteModal(true);
  };

  const getProjects = (query: string) => {
    let url = `project/get?clientId=${id}&` + query;
    globalApi
      .get(url)
      .then((data) => {
        if (data?.status?.toLowerCase() === "failed") {
          addToast(errorMsg.PROJECT_GET, "error");
        } else if (data === 401) {
          setShowLogout(true);
        } else {
          setRows(data.response.rows);
          setTotalRecords(data?.response?.totalCount);
          setSpinner(false);
        }
      })
      .catch((err) => {
        addToast(errorMsg.PROJECT_GET, "error");
        setSpinner(false);
      });
  };

  const searchRecord = (e: any, column: any, filterKeys: any) => {
    let keyData = { ...filterKeys };
    setSearchFieldKey(keyData);
    let queryPrams = Object.keys(filterKeys)
      .map((filterKey: any) => {
        if (filterKeys[filterKey]) {
          return filterKey + "=" + filterKeys[filterKey];
        }
      })
      .join("&");
    setSpinner(true);
    getProjects(queryPrams);
  };
  const onPageChange = (event: any) => {
    let pageUrl = `page=${event.page}&limit=${event.rows}`;
    setSpinner(true);
    getProjects(pageUrl);
    setPageRows(event.rows);
    setFirst(event.first);
  };

  const handlePageChange = (val: number) => {
    getProjects(`page=${val}&limit=10`);
  };

  const handleDeleteYes = () => {
    setSpinner(true);
    globalApi
      .deleteApi("project/delete/" + data.id)
      .then((resp) => {
        if (resp === 500) {
          toast.error("Unable to delete");
        } else if (resp?.status?.toLowerCase() === "failed") {
          addToast(errorMsg.PROJECT_DELETE, "error");
          setShowDeleteModal(false);
        } else if (data === 401) {
          setShowLogout(true);
        } else {
          if (resp) {
            addToast("Project deleted successfully", "success");
            getProjects("page=0&limit=100");
            setPageRows(10);
            setFirst(0);
            setShowDeleteModal(false);
          }
        }
        setSpinner(false);
      })
      .catch((err) => {
        addToast(errorMsg.PROJECT_DELETE, "error");
        setSpinner(false);
      });
  };

  const goToSurvey = (rowData: any) => {
    dispatch(projectTsx(rowData));
    navigate("/delivers/surveys/" + rowData.id);
  };

  const handleDeliverables = (rowData: any) => {
    navigate("/delivers/deliverables/" + rowData.projectid);
  };
  const actionsTemplate = (rowData: any, column: any) => {
    return (
      <>
        <span className="ml-2">
          <Tooltip target=".edit-button" position="left" />
          <i
            className="pi pi-pencil clr-primary edit-button pointer"
            onClick={() => navigate("/delivers/edit/" + rowData.id)}
            data-pr-tooltip="Edit"
          />{" "}
        </span>
        <span className="ml-3">
          <Tooltip target=".survey-button" position="left" />
          <i
            className="pi pi-database clr-primary pointer survey-button"
            onClick={() => goToSurvey(rowData)}
            data-pr-tooltip="Surveys"
          />
        </span>
        <span className="ml-3">
          <Tooltip target=".delete-button" position="left" />
          <i
            className="pi pi-trash clr-primary delete-button pointer"
            onClick={() => handleDelete(rowData, column)}
            data-pr-tooltip="Archive"
          />
        </span>
        <span className="ml-3">
          <Tooltip target=".deliverable-button" position="left" />
          <i
            className="pi pi-check-circle clr-primary deliverable-button pointer"
            onClick={() => handleDeliverables(rowData)}
            data-pr-tooltip="Deliverables"
          />
        </span>
      </>
    );
  };

  const handleActive = (e: any, data: any) => {
    setSpinner(true);
    globalApi
      .post("project/update", { id: data.id, isActive: e.target.checked })
      .then((res) => {
        if (res?.status?.toLowerCase() === "success") {
          res.response.isActive
            ? toast.success("Project activated successfully")
            : toast.success("Project deactivated successfully");
        }
        getProjects(`page=0&limit=10`);
        setSpinner(false);
      });
  };

  const handleView = (rowData: any) => {
    setRow(rowData);
    setViewModal(true);
  };
  const handleClose = () => {
    setRow({});
    setViewModal(false);
  };
  const header = () => {
    return <div className="m-t-10">Project: {row.projectid}</div>;
  };
  if (columns && columns.length > 0) {
    columns[columns.length - 1].body = actionsTemplate;
  }

  const handleUploadModal = (data: any) => {
    navigate("/uploadexcel", { state: data });
  };

  const handleViewFile = (data: any) => {
    navigate("/uploadexcel", { state: data });
  };

  const [isDelete, setIsDelete] = useState<boolean>(false);
  const [delData, setDelData] = useState<any>(null);

  const handleDeleteFile = () => {
    setSpinner(true);
    globalApi
      .post("projectFile/delete", {
        projectId: delData?.id,
        filePath: delData?.formUrl,
      })
      .then((res: any) => {
        if (res?.status === "Failed") {
          toast.error(res.message);
          setSpinner(false);
        } else {
          setIsDelete(false);
          toast.success("File deleted successfully");
          getProjects(`page=0&limit=10`);
          setSpinner(false);
        }
      })
      .catch((err) => {
        toast.error(err.message);
        setSpinner(false);
      });
  };

  const handleDeleteFileModal = (data: any) => {
    setIsDelete(true);
    setDelData(data);
  };

  const hadleEdit = (data: any) => {
    navigate(`/delivers/edit/${data.id}`, {
      state: location,
    });
    sessionStorage.setItem("editProjectData", JSON.stringify(data));
    sessionStorage.setItem(
      "prevProjectRoute",
      JSON.stringify({
        name: data.projectName,
        link: `/delivers/${data.clientId}`,
      })
    );
  };

  return (
    <div className="mt-3 oy">
      <Loader loader={spinner} />
      <div className="ptable">
        <ToastContainer />
        <DeleteModal
          show={isDelete}
          onCloseClick={() => setIsDelete(false)}
          onDeleteClick={handleDeleteFile}
        />

        <Container fluid>
          <div className="d-flex justify-content-between align-items-center m-b-20">
            <BreadCrumb
              title={"Projects"}
              pageTitle={JSON.parse(prevRoute).name}
              pageLink={JSON.parse(prevRoute).link}
            />
            <Button
              onClick={() =>
                navigate(`/delivers/create/${id}`, { state: location })
              }
              color="primary"
            >
              + Add Project
            </Button>
          </div>

          <BasicDataTable
            type="PROJECT"
            data={rows}
            view={handleView}
            onEdit={hadleEdit}
            gotToSurvey={goToSurvey}
            onClickDelete={handleDelete}
            handleActive={handleActive}
            uploadFile={handleUploadModal}
            viewFile={handleViewFile}
            deleteFile={handleDeleteFileModal}
          />

          {totalRecords > 10 && (
            <Pagination
              count={totalRecords}
              itemsPerPage={10}
              handlePageChange={handlePageChange}
            />
          )}
        </Container>

        <DeleteModal
          show={showDeleteModal}
          onCloseClick={() => setShowDeleteModal(false)}
          onDeleteClick={handleDeleteYes}
        />
        {viewModal && (
          <Modal isOpen={viewModal} centered className="w-75">
            <ModalHeader
              className="modal-title p-2"
              id="myModalLabel"
              toggle={() => {
                setViewModal(false);
              }}
            >
              <span className="text-light">
                Project Name: {row.projectName}
              </span>
            </ModalHeader>
            <div style={{ alignContent: "center", padding: 30 }}>
              <div className="row m-t-10">
                <div className="col-3 p-r-10">
                  <div className="opacity-9">Industry :</div>
                  <div>{row.industry}</div>
                </div>
                <div className="col-3 p-r-10">
                  <div className="opacity-9">Module :</div>
                  <div>{row.moduleCategory}</div>
                </div>
                <div className="col-3 p-r-10">
                  <div className="opacity-9">Sub Module :</div>
                  <div>{row.subModule}</div>
                </div>
                {row.subModule === "Oil and Gas" && (
                  <div className="col-3 p-r-10">
                    <div className="opacity-9">SME :</div>
                    <div>{row.sme}</div>
                  </div>
                )}
                {row.subModule === "Oil and Gas" && (
                  <div className="col-3 p-r-10">
                    <div className="opacity-9">Total Assets :</div>
                    <div>{row.assetAssignedCount}</div>
                  </div>
                )}
                {(row.subModule === "Land Survey" ||
                  row.subModule === "Mining Survey" ||
                  row.subModule === "Mining") && (
                  <div className="col-3 p-r-10">
                    <div className="opacity-9">Area :</div>
                    <div>
                      {row.areasAcres} {row.unitConversions}
                    </div>
                  </div>
                )}
                {(row.subModule === "Land Survey" ||
                  row.subModule === "Mining Survey" ||
                  row.subModule === "Mining") && (
                  <div className="col-3 p-r-10">
                    <div className="opacity-9">Total Survey :</div>
                    <div>{row.totalSurvey}</div>
                  </div>
                )}

                {(row.subModule === "Land Survey" ||
                  row.subModule === "Mining Survey" ||
                  row.subModule === "Mining") && (
                  <div className="col-3 p-r-10">
                    <div className="opacity-9">Frequency :</div>
                    <div>
                      {frequencies.find(
                        (frequency) => frequency.value === row.frequency
                      )?.frequency || row.frequency}
                    </div>
                  </div>
                )}
                <div className="col-3 p-r-10">
                  <div className="opacity-9">Country :</div>
                  <div>{row.country}</div>
                </div>
                <div className="col-3 p-r-10">
                  <div className="opacity-9">State :</div>
                  <div>{row.state}</div>
                </div>
                <div className="col-3 p-r-10">
                  <div className="opacity-9">Location :</div>
                  <div>{row.location}</div>
                </div>
                <div className="col-3 p-r-10">
                  <div className="opacity-9">Project Manager :</div>
                  <div>{row.projectManagers?.split("-")[0]}</div>
                </div>
                <div className="col-3 p-r-10">
                  <div className="opacity-9">Description :</div>
                  <div>{row.description}</div>
                </div>
              </div>
            </div>
          </Modal>
        )}
        {showLogout && (
          <UnAuthDialog
            visible={showLogout}
            onHide={() => setShowLogout(false)}
          />
        )}
      </div>
    </div>
  );
};

export default Projects;

import { Dropdown } from "primereact/dropdown";
import { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import ActionButtons from "../../actionsComponent/ActionButtons";
import Breadcrum from "../../commons/Breadcrum";
import { addToast, getMode } from "../../commons/common";
import { errorMsg, frequenciesArr, messages } from "../../commons/constant";
import InputComponent from "../../inputComponent/inputComponent";

import * as globalApi from "../../../api/global.api";
import Loader from "../../CommonComponent/Loader";
import { ToastContainer, toast } from "react-toastify";
import UnAuthDialog from "../../commons/UnAuthDialog";
import { MultiSelect } from "primereact/multiselect";
import BreadCrumb from "../../Common/BreadCrumb";
import { Capitalize, alphanumbericRegex } from "../../commons/functions";
import { useAppSelector } from "../../../store/hooks";

const CreateProject = () => {
  const user = useAppSelector((state) => state.user);
  const loc = useLocation()?.state;
  console.log(loc)
  let prevRoute: any = sessionStorage.getItem("prevRoute");
  let prevProjectRoute: any = sessionStorage.getItem("prevProjectRoute");
  let countriesList: any = localStorage.getItem("countries");
  const clientId = useParams().id;
  const navigate = useNavigate();
  const [spinner, setSpinner] = useState(false);
  let formType = getMode(window.location.hash);
  let mode = formType.mode;
  let id = formType.id;
  const [industries, setIndustries] = useState<any>([]);
  const [modules, setModules] = useState<any>([]);
  const [subModules, setSubModules] = useState<any>([]);
  const [unitConversions, setUnitConversions] = useState<any>([]);
  const [frequencies, setfrequncies] = useState(frequenciesArr);
  const [countries, setCountries] = useState<any>([]);
  const [states, setStates] = useState<any>([]);
  const [categories, setCategories] = useState<any>([]);
  const [smeOptions, setSmeOptions] = useState<any>([]);

  const [formState, setFormState] = useState<any>({});
  const [errors, setErrors] = useState<any>({});
  const [showLogout, setShowLogout] = useState(false);

  const [projectManagers, setProjectManagers] = useState<any>([]);

  const alpahnumCheck = (str: string) => {
    console.log("6666");

    const alphanumericRegex = /^[a-zA-Z0-9 ]+$/;
    if (alphanumericRegex.test(str)) {
      return str;
    } else {
      toast.error("please enter alphanumeric value");
    }
  };
  const getProject = (id: any) => {
    setSpinner(true);
    globalApi.get("project/find/" + id).then((resp) => {
      if (resp?.status?.toLowerCase() === "failed") {
        setSpinner(false);
        addToast(errorMsg.PROJECT_FIND, "error");
      } else if (resp === 401) {
        setSpinner(false);
        setShowLogout(true);
      } else {
        getSubModule(resp.response.moduleCategory);
        getStates(resp.response.country?.split("_")[1]);
        setFormState({
          clientId: resp.response.clientId,
          projectname: resp.response.projectName,
          industry: resp.response.industry,
          module: resp.response.moduleCategory,
          subModule: resp.response.subModule,
          areasAcres: resp.response.areasAcres,
          unitConversion: resp.response.unitConversions,
          frequency: resp.response.frequency,
          address: resp.response.location,
          country: resp.response.country,
          state: resp.response.state,
          description: resp.response.description,
          assetAssignedCount: resp.response.assetAssignedCount,
          sme: resp.response.sme,
          projectManagers: resp.response?.projectManagers?.split(","),
        });
        setSpinner(false);
      }
    });
  };

  const getModules = () => {
    let mods: any = loc.clientModules?.map((e: any) => e.module);
    let unique: any = new Set(mods);
    setModules([...unique]?.map((e: any) => ({ moduleName: e })));
  };

  const getUnitConversion = () => {
    setSpinner(true);
    globalApi.get("getEnums?enumType=UNIT_CONVERSION").then((data) => {
      if (data?.status?.toLowerCase() === "failed") {
        setSpinner(false);
        addToast(errorMsg.CATEGORY_GET, "error");
      } else if (data === 401) {
        setShowLogout(true);
        setSpinner(false);
      } else {
        setUnitConversions(data.appEnum);
        setSpinner(false);
      }
    });
  };

  const getSubModule = (val: any) => {
    let filtermodules = loc?.clientModules?.filter(
      (e: any) => e.module === val
    );
    let dropvalues = filtermodules?.map((e: any) => ({
      subModule: e?.subModule[0],
    }));
    setSubModules(dropvalues);
  };

  const getSME = () => {
    setSpinner(true);
    globalApi.get("user/get/Sme").then((res) => {
      if (res?.status?.toLowerCase() === "success") {
        let result = res?.response.map((e: any) => {
          return {
            name:
              Capitalize(e.User.firstName) + " " + Capitalize(e.User.lastName),
            value: e.User.firstName + " " + e.User.lastName,
          };
        });
        setSmeOptions(result);
        setSpinner(false);
      } else {
        toast.error(res?.errormessage);
        setSpinner(false);
      }
    });
  };

  const getCountries = () => {
    setSpinner(true);
    globalApi.get("getCountries").then((res) => {
      localStorage.setItem("countries", JSON.stringify(res));
      setCountries(res);
      setSpinner(false);
    });
  };

  const getStates = (e: any) => {
    setSpinner(true);
    globalApi.get(`getStateByCountry?countryCode=${e}`).then((res) => {
      setStates(res);
      setSpinner(false);
    });
  };

  useEffect(() => {
    setIndustries([{ enumName: loc?.industry }]);
    getSME();
    getUsers();
    getCountries();
    getModules();
    getUnitConversion();
    if (mode === "edit") {
      getProject(id);
    }
  }, []);

  const getUsers = () => {
    globalApi.get("user/get/Project Manager").then((data) => {
      let projectManagerArr: any = [];
      if (data && data.response) {
        data.response.map((obj: any) => {
          obj.name_role =
            obj?.User?.firstName +
            " " +
            obj?.User?.lastName +
            " - " +
            obj?.roleName;
          projectManagerArr.push({ obj_name: obj.name_role });
        });
      }
      setProjectManagers(projectManagerArr);
      setSpinner(false);
    });
  };

  const handleFormChange = (e: any, field: any, type: any, error: any) => {
    errors[field] = error[field];
    setErrors(errors);
    setFormState({ ...formState, [field]: e });
  };

  const handleTextAndDropDownChange = (e: any, type: any) => {
    if (type === "country") {
      let country = countries.filter((ele: any) => ele.name === e.value)[0];
      setFormState({
        ...formState,
        [type]: country.name + "_" + country.isoCode,
      });
      getStates(country.isoCode);
    } else {
      setFormState({ ...formState, [type]: e.target.value });
    }
  };

  const handleSaveDisable = () => {
    if (
      formState.projectname?.trim() === "" ||
      !formState?.industry ||
      !formState?.module ||
      !formState?.subModule ||
      !formState?.country ||
      !formState?.state ||
      formState?.address?.trim === ""
    ) {
      return true;
    } else if (
      formState?.subModule === "Mining Survey" ||
      formState?.subModule === "Land Survey" ||
      formState?.subModule === "Mining"
    ) {
      if (!formState?.areasAcres || !formState?.unitConversion) {
        return true;
      }
    } else if (formState?.subModule === "Oil and Gas") {
      if (!formState?.sme || !formState?.assetAssignedCount) {
        return true;
      }
    }
    return false;
  };

  const handleCancel = () => {
    mode === "create"
      ? navigate(`/delivers/${clientId}`, { state: loc })
      : navigate(`/delivers/${formState.clientId}`, { state: loc });
  };

  const handleSave = () => {
    setSpinner(true);
    let newObj = {};
    let url = "";
    let obj: any = {
      clientId: Number(clientId),
      projectName: formState.projectname,
      industry: formState?.industry,
      moduleCategory: formState.module,
      subModule: formState.subModule,
      areasAcres: Number(formState?.areasAcres),
      unitConversions: formState?.unitConversion,
      frequency: formState.frequency ? formState.frequency : "0",
      location: formState.address,
      country: formState.country,
      state: formState.state,
      projectManagers: formState.projectManagers?.join(","),
      description: formState.description,
      sme: formState?.sme,
      assetAssignedCount: formState?.assetAssignedCount,
      createdBy: user.firstName + " " + user.lastName,
    };

    if (mode === "create") {
      newObj = obj;
      url = "project/add";
    } else {
      newObj = {
        ...obj,
        clientId: Number(formState.clientId),
        id: Number(clientId),
      };
      url = "project/update";
    }

    globalApi
      .post(url, newObj)
      .then((data: any) => {
        if (data?.status?.toLowerCase() === "failed") {
          setSpinner(false);
          addToast(
            mode === "create" ? errorMsg.PROJECT_ADD : errorMsg.PROJECT_UPDATE,
            "error"
          );
        } else if (data === 401) {
          setSpinner(false);
          setShowLogout(true);
        } else {
          if (data) {
            addToast(
              mode === "create"
                ? messages.PROJECT_CREATE
                : messages.PROJECT_UPDATE,
              "success"
            );
            setSpinner(false);
            setTimeout(() => {
              navigate(-1);
            }, 500);
          }
        }
      })
      .catch((err) => {
        setSpinner(false);
        addToast(
          mode === "create" ? errorMsg.PROJECT_ADD : errorMsg.PROJECT_UPDATE,
          "error"
        );
      });
  };

  return (
    <div className="ptable mt-3">
      <Loader loader={spinner} />
      <div className="p-25">
        <ToastContainer />
        <div className="m-b-20">
          {/* <Breadcrum /> */}
          <BreadCrumb
            pageTitle1={JSON.parse(prevRoute)?.name}
            pageLink1={JSON.parse(prevRoute)?.link}
            pageTitle={
              mode === "create"
                ? "Projects"
                : JSON.parse(prevProjectRoute)?.name
            }
            pageLink={
              mode === "create"
                ? `/delivers/${clientId}`
                : JSON.parse(prevProjectRoute)?.link
            }
            title={mode === "create" ? "Add Project" : "Edit Project"}
          />
        </div>
        <div className="row">
          <div className="col-4">
            {mode === "view" ? (
              <p className="fs-20">Project Name : {formState.projectname}</p>
            ) : (
              <InputComponent
                inputLabel={true}
                inputLabelText="Project Name"
                mandatory={true}
                name="projectname"
                placeholder="Enter Project Name"
                value={formState.projectname}
                required={true}
                type="text"
                // validator="Alphanumeric"
                errorMessage="Please enter name without special case Ex: $,#,..."
                onKeyChange={handleFormChange}
              />
            )}
          </div>
          <div className="col-4">
            {mode === "view" ? (
              <p className="fs-20">
                Industry :{" "}
                {
                  industries.find(
                    (industry: any) =>
                      industry.categoryid === formState.categoryid
                  )?.categoryname
                }
              </p>
            ) : (
              <div>
                <label>
                  Industry
                  <span className="mandatory"> *</span>
                </label>
                <Dropdown
                  value={formState.industry}
                  options={industries}
                  optionLabel="enumName"
                  optionValue="enumName"
                  className="w-100 border ht-50"
                  onChange={(e) => handleTextAndDropDownChange(e, "industry")}
                  placeholder="Select a Industry"
                  // filter
                />
              </div>
            )}
          </div>
          <div className="col-4">
            {mode === "view" ? (
              <p className="fs-20">
                Module Category:
                {
                  categories.find(
                    (category: any) =>
                      category.categoryid === formState.categoryid
                  )?.categoryname
                }
              </p>
            ) : (
              <div>
                <label>
                  Module Category
                  <span className="mandatory"> *</span>
                </label>
                <Dropdown
                  value={formState.module}
                  options={modules}
                  optionLabel="moduleName"
                  optionValue="moduleName"
                  className="w-100 border ht-50"
                  onChange={(e) => {
                    handleTextAndDropDownChange(e, "module");
                    getSubModule(e.target.value);
                  }}
                  placeholder="Select a Category"
                  // filter
                />
              </div>
            )}
          </div>
          <div className="col-4">
            {mode === "view" ? (
              <p className="fs-20">
                Category :{" "}
                {
                  categories.find(
                    (category: any) =>
                      category.categoryid === formState.categoryid
                  )?.categoryname
                }
              </p>
            ) : (
              <div>
                <label>
                  Sub Module
                  <span className="mandatory"> *</span>
                </label>
                <Dropdown
                  value={formState.subModule}
                  options={subModules}
                  optionLabel="subModule"
                  optionValue="subModule"
                  className="w-100 border ht-50"
                  onChange={(e) => handleTextAndDropDownChange(e, "subModule")}
                  placeholder="Select a Sub Module"
                  // filter
                />
              </div>
            )}
          </div>

          {formState.module === "Survey" && (
            <>
              <div className="col-4">
                {mode === "view" ? (
                  <p className="fs-20">
                    Area/Distance : {formState.areas_acres}
                  </p>
                ) : (
                  <InputComponent
                    inputLabel={true}
                    inputLabelText="Area/Distance"
                    mandatory={true}
                    name="areasAcres"
                    placeholder="Enter Area"
                    value={formState.areasAcres}
                    required={true}
                    type="number"
                    onKeyChange={handleFormChange}
                    validator={"AREA"}
                  />
                )}
              </div>

              <div className="col-4">
                {mode === "view" ? (
                  <p className="fs-20">
                    Unit :{" "}
                    {
                      categories.find(
                        (category: any) =>
                          category.categoryid === formState.categoryid
                      )?.unit
                    }
                  </p>
                ) : (
                  <div>
                    <label>
                      Unit
                      <span className="mandatory"> *</span>
                    </label>
                    <Dropdown
                      value={formState.unitConversion}
                      options={unitConversions}
                      optionLabel="enumName"
                      optionValue="enumName"
                      className="w-100 border ht-50"
                      onChange={(e) =>
                        handleTextAndDropDownChange(e, "unitConversion")
                      }
                      placeholder="Select a Sub Module"
                      filter
                    />
                  </div>
                )}
              </div>
              {formState.module === "Survey" && (
                <div className="col-4">
                  {mode === "view" ? (
                    <p className="fs-20">
                      Frequency :{" "}
                      {
                        frequencies.find(
                          (freq: any) => freq.value === formState.frequency
                        )?.frequency
                      }
                    </p>
                  ) : (
                    <div>
                      <label>Frequency</label>
                      <Dropdown
                        value={formState.frequency}
                        options={frequencies}
                        optionLabel="frequency"
                        optionValue="frequency"
                        className="w-100 border ht-50"
                        onChange={(e) =>
                          handleTextAndDropDownChange(e, "frequency")
                        }
                        placeholder="Select a Frequency"
                        filter
                      />
                    </div>
                  )}
                </div>
              )}
            </>
          )}

          {formState?.frequency === "Custom" && (
            <div className="col-4">
              {mode === "view" ? (
                <p className="fs-20">
                  Custom Frequency : {formState.projectname}
                </p>
              ) : (
                <InputComponent
                  inputLabel={true}
                  inputLabelText="Custom Frequency"
                  mandatory={true}
                  name="customFrequency"
                  placeholder="Enter Project Name"
                  value={formState.projectname}
                  required={true}
                  type="text"
                  onKeyChange={handleFormChange}
                />
              )}
            </div>
          )}

          <div className="col-4">
            {mode === "view" ? (
              <p className="fs-20">Country : {formState.state}</p>
            ) : (
              <div>
                <label>
                  Country
                  <span className="mandatory"> *</span>
                </label>
                <Dropdown
                  value={formState.country?.split("_")[0]}
                  options={countries}
                  optionLabel="name"
                  optionValue="name"
                  className="w-100 border ht-50"
                  onChange={(e) => {
                    handleTextAndDropDownChange(e, "country");
                  }}
                  placeholder="Select a country"
                  filter
                />
              </div>
            )}
          </div>
          <div className="col-4">
            {mode === "view" ? (
              <p className="fs-20">State : {formState.state}</p>
            ) : (
              <div>
                <label>
                  State
                  <span className="mandatory"> *</span>
                </label>
                <Dropdown
                  value={formState.state}
                  options={states}
                  optionLabel="name"
                  optionValue="name"
                  className="w-100 border ht-50"
                  onChange={(e) => handleTextAndDropDownChange(e, "state")}
                  placeholder="Select a State"
                  filter
                />
              </div>
            )}
          </div>
          <div className="col-4">
            {mode === "view" ? (
              <p className="fs-20">Address : {formState.location}</p>
            ) : (
              <InputComponent
                inputLabel={true}
                inputLabelText="Address"
                mandatory={true}
                name="address"
                placeholder="Enter Address"
                value={Capitalize(formState.address)}
                required={true}
                type="text"
                onKeyChange={handleFormChange}
              />
            )}
          </div>
          {formState.industry === "Oil and Gas" && (
            <>
              <div className="col-4">
                {mode === "view" ? (
                  <p className="fs-20">Country : {formState.state}</p>
                ) : (
                  <div>
                    <label>
                      Assign to SME
                      <span className="mandatory"> *</span>
                    </label>
                    <Dropdown
                      value={formState.sme}
                      options={smeOptions}
                      optionLabel="name"
                      optionValue="value"
                      className="w-100 border ht-50"
                      onChange={(e) => {
                        handleTextAndDropDownChange(e, "sme");
                      }}
                      placeholder="Select SME"
                      filter
                    />
                  </div>
                )}
              </div>

              <div className="col-4">
                {mode === "view" ? (
                  <p className="fs-20">Total Assets : {formState.location}</p>
                ) : (
                  <InputComponent
                    inputLabel={true}
                    inputLabelText="Total Assets"
                    mandatory={true}
                    name="assetAssignedCount"
                    placeholder="Enter total assets"
                    value={formState.assetAssignedCount}
                    required={true}
                    type="text"
                    validator={"Number"}
                    onKeyChange={handleFormChange}
                  />
                )}
              </div>
            </>
          )}
          <div className="col-4">
            <div>
              <label>Project Managers</label>
              <MultiSelect
                value={formState.projectManagers}
                options={projectManagers}
                optionLabel="obj_name"
                optionValue="obj_name"
                className="w-100 border ht-50 multiselect-div"
                onChange={(e) =>
                  handleTextAndDropDownChange(e, "projectManagers")
                }
                placeholder="Select a Project Manager"
              />
            </div>
          </div>
          <div className="col-4">
            {mode === "view" ? (
              <p className="fs-20">Description : {formState.description}</p>
            ) : (
              <InputComponent
                inputLabel={true}
                inputLabelText="Description"
                mandatory={false}
                name="description"
                placeholder="Enter Description"
                value={Capitalize(formState.description)}
                required={true}
                type="text"
                onKeyChange={handleFormChange}
              />
            )}
          </div>
        </div>

        <>
          {mode !== "view" && (
            <ActionButtons
              buttons={["Save", "Cancel"]}
              onSave={handleSave}
              mode={mode}
              onCancel={handleCancel}
              disabled={handleSaveDisable()}
            />
          )}
        </>
      </div>
      {showLogout && (
        <UnAuthDialog
          visible={showLogout}
          onHide={() => setShowLogout(false)}
        />
      )}
    </div>
  );
};

export default CreateProject;

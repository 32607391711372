import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
} from "reactstrap";

const Legends = ({ layers, raw }) => {
  const [isLegends, setIsLegends] = useState(false);
  const [data, setData] = useState([]);
  const [layerlist, setlayerlist] = useState([]);

  const handleLegendClick = () => {
    const highPrio = layerlist
      .filter((layer) => layer.visibility === 1)
      .reduce((maxLayer, currentLayer) => {
        return currentLayer.priority > maxLayer.priority
          ? currentLayer
          : maxLayer;
      });

    const baseUrl = process.env.REACT_APP_GEOSERVER_URL + "wms";
    const params = {
      REQUEST: "GetLegendGraphic",
      WIDTH: 20,
      HEIGHT: 20,
      LAYER: highPrio?.sourceName,
      format: "image/png",
    };

    const constructUrl = (baseUrl, params) => {
      setData([]);
      const queryParams = new URLSearchParams(params).toString();
      return `${baseUrl}?${queryParams}`;
    };

    const legendUrl = constructUrl(baseUrl, params);
    console.log(legendUrl)
    setData(legendUrl);
    // fetch(legendUrl)
    //   .then((res) => {
    //     if (!res.ok) {
    //       throw new Error(`HTTP error! Status: ${res.status}`);
    //     }
    //     return res.json(); // Parse the response as JSON
    //   })
    //   .then((data) => {
    //     const { Legend } = data;
    //     const raster = [];
    //     Legend.map((e) => {
    //       e.rules.map((ele) => {
    //         ele?.symbolizers?.map((item) => {
    //           if (item?.Raster && item?.Raster?.colormap) {
    //             item?.Raster?.colormap?.entries?.map((col) => {
    //               raster.push({ color: col?.color, value: col?.label });
    //             });
    //           } else if (item?.Polygon) {
    //             raster.push({ color: item?.Polygon?.fill, value: ele?.title });
    //           } else if (item?.Line) {
    //             raster.push({ color: item?.Line?.stroke, value: ele?.title });
    //           } else if (item?.Point) {
    //             raster.push({
    //               color: item?.Point?.graphics[0]?.fill,
    //               value: ele?.title,
    //             });
    //           }
    //         });
    //       });
    //     });

    //     setData(raster);
    //   })
    //   .catch((error) => {
    //     console.error("There was an error with the fetch operation:", error);
    //   });
  };

  const toggleLegends = () => {
    if (!isLegends) {
      setIsLegends(true);
      handleLegendClick();
    } else {
      setIsLegends(false);
    }
  };

  useEffect(() => {
    let final = raw?.map((ele) => {
      let data = layers?.find(
        (item) => item.displayname === ele.sourceDisplayName
      );
      return { ...ele, visibility: data.visibility };
    });
    setlayerlist(final);
  }, [layers]);

  return (
    <div className="legends">
      <UncontrolledDropdown
        isOpen={isLegends}
        toggle={toggleLegends}
        className="me-2"
        direction="up"
      >
        <DropdownToggle color="primary">
          <i className="bx bx-left-indent fs-2"></i>
        </DropdownToggle>
        {data.length > 0 && (
          <DropdownMenu
            style={{
              maxHeight: "600px",
              overflowY: "auto",
              transform: "translate(90px, -49.5px)",
            }}
          >
            <DropdownItem>
              <img src={data} />
            </DropdownItem>
            {/* {data.map((ele) => (
              <DropdownItem>
                <i className="bx bxs-square" style={{ color: `${ele?.color}` }}></i>{" "}
                <span>{ele.value}</span>
              </DropdownItem>
            ))} */}
          </DropdownMenu>
        )}
      </UncontrolledDropdown>
    </div>
  );
};

export default Legends;

import React, { useState } from "react";
import Lightbox from "react-image-lightbox";
import MarkImageModal from "./MarkImageModal";
import { Card } from "reactstrap";
import ImageComponent from "../../components/Common/ImageComponent";

const ImageCard = ({
  data,
  records,
  status,
  currentImage,
  getComponents,
  index,
}) => {
  let currentImageIndex = sessionStorage.getItem("currentImageIndex");
  const [isLightbox, setIsLightbox] = useState(false);
  const [id, setId] = useState();
  let fileName = data?.fileName?.split(".")[0];
  let total = records?.length;

  const [isModal, setIsModal] = useState(false);
  const [currentImg, setCurrentImg] = useState(null);

  const handleLightbox = (data) => {
    let idx = records?.findIndex((e) => e.fileName === data.fileName);
    setId(idx);
    setIsLightbox(true);
  };

  let imgl = (img) => {
    let url;
    if (
      img?.includes("http://") ||
      img?.includes("data:") ||
      img?.includes("https://")
    ) {
      url = img;
    } else {
      url = process.env.REACT_APP_API_URL + "view/" + img;
    }
    return url;
  };

  const handleImageClick = () => {
    setCurrentImg(data);
    setIsModal(true);
  };
  return (
    <React.Fragment>
      {isLightbox && (
        <Lightbox
          mainSrc={imgl(records[id].defectFile)}
          imageTitle={records[id].fileName}
          imageCaption={id + 1}
          onCloseRequest={() => setIsLightbox(false)}
          onMovePrevRequest={() => setId((id - 1 + total) % total)}
          onMoveNextRequest={() => setId((id + 1) % total)}
          nextSrc={imgl(records[(id + 1) % total].defectFile)}
          prevSrc={imgl(records[(id - 1 + total) % total].defectFile)}
        />
      )}
      <Card
        className="img-card-div"
        style={{
          border:
            currentImageIndex !== null && index === +currentImageIndex
              ? "5px solid red"
              : "none",
        }}
      >
        <i
          className="bx bx-fullscreen img-card-full fs-3"
          onClick={() => handleLightbox(data)}
        ></i>
        <ImageComponent
          className="pointer"
          src={data?.defectFile}
          onClick={() => {
            status !== "Completed" && handleImageClick();
          }}
        />
        <span className="p-2 text-center">{fileName}</span>
      </Card>

      {isModal && (
        <MarkImageModal
          isModal={isModal}
          records={records}
          image={currentImg}
          setIsModal={setIsModal}
          getComponents={getComponents}
        />
      )}
    </React.Fragment>
  );
};

export default ImageCard;

import React, { useEffect, useState } from "react";
import ClientHeader from "../Dashboard/ClientHeader";
import TxMap from "./TxMap";
import { useLocation, useNavigate } from "react-router-dom";
import Loader from "../../components/CommonComponent/Loader";
import TxCharts from "./TxCharts";
import Select from "react-select";
import { createSelectOption } from "../Components/Utils";

const ProjectDashboard = () => {
  const location = useLocation().state;
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [corridors, setCorridors] = useState([]);
  const [selectedCorridor, setSelectedCorridor] = useState(null);
  const [corridorId, setCorridorId] = useState(null);
  const [corridorData, setCorridorData] = useState([]);
  const [sourceData, setSourceData] = useState([]);

  const getCorridors = (val) => {
    let list = createSelectOption(val, "corridorName");
    setCorridors(list);
    setCorridorData(val);
  };

  const handleChange = (val) => {
    setSelectedCorridor(val);
  };

  useEffect(() => {
    if (selectedCorridor) {
      let corridor = corridorData?.find(
        (e) => e.corridorName === selectedCorridor.value
      );
      navigate("/client-tx-towers", {
        state: {
          project: location,
          corridor: corridor,
          sources: sourceData,
        },
      });
    }
  }, [selectedCorridor]);
  return (
    <React.Fragment>
      <div className="tx-coridor-select">
        <Select
          options={corridors}
          value={selectedCorridor}
          onChange={(val) => {
            handleChange(val);
          }}
        />
      </div>
      <ClientHeader />
      {loading && (
        <div className="loading">
          <Loader loader={loading} />
        </div>
      )}
      <div className="content">
        <TxMap
          project={location}
          setLoading={setLoading}
          getCorridors={getCorridors}
          setSourceData={setSourceData}
        />
        <div className="charts-content">
          <TxCharts project={location} />
        </div>
      </div>
    </React.Fragment>
  );
};

export default ProjectDashboard;

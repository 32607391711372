import React, { useEffect, useState } from "react";
import { Button, Card, Col, Offcanvas, OffcanvasHeader, Row } from "reactstrap";
import delIcon from "../../assets/svg-new/deletewhite.svg";
import editIcon from "../../assets/svg-new/edit.svg";
import prevIcon from "../../assets/svg-new/prev-img.svg";
import nextIcon from "../../assets/svg-new/next-img.svg";
import dot from "../../assets/svg-new/smallcircle.svg";
import { OffcanvasBody } from "react-bootstrap";
import * as api from "../../api/global.api";
import { Tooltip } from "../Components/Utils";
import DeleteModal from "../../components/Common/DeleteModal";
import { toast } from "react-toastify";
import { useLocation, useNavigate } from "react-router-dom";
import MarkImageModal from "./MarkImageModal";
import ImageComponent from "../../components/Common/ImageComponent";

const ComponentCard = ({ data, getComponents, setLoading, status, did }) => {
  let componentName = data[0];
  let details = data[1];
  console.log('details',details)
  const [id, setId] = useState(0);
  const [img, setImg] = useState(null);
  const [isOffcanvas, setIsOffcanvas] = useState(false);
  const [isDelete, setIsDelete] = useState(false);
  const [isModal, setIsModal] = useState(false);
  const [currentImage, setCurrentImg] = useState(null);

  const deleteComponent = async () => {
    setLoading(true);
    let idx = details[id]?.id;
    let res = await api.post(`deletetowercomponent`, { id: idx });
    if (res?.status === "Success") {
      toast.success("Component deleted successfully");
      setImg("");
      setIsDelete(false);
      getComponents();
      setLoading(false);
    } else {
      toast.error(res?.errorMessage);
      setLoading(false);
    }
  };

  const handleEdit = () => {
    let obj = {
      ...details[id].towerComponentDetails[0],
      base64: img,
      componentName: componentName,
      component: details[id],
    };
    setCurrentImg(obj);
    setIsModal(true);
  };

  useEffect(() => {
    let imgsrc = details[id]?.towerComponentDetails[0]?.modifiedFile;
    
    // let imgmain =
    //   process.env?.REACT_APP_UPLOAD_TYPE === "local"
    //     ? process.env.REACT_APP_API_URL + "view/" + imgsrc
    //     : imgsrc;
    setImg(imgsrc);
  }, [id]);

  // console.log(img);
  
  return (
    <div className="m-3">
      <DeleteModal
        show={isDelete}
        onCloseClick={() => setIsDelete(false)}
        onDeleteClick={deleteComponent}
      />
      <Card className="mb-3">
        <div className="comp-img">
          <ImageComponent src={img} alt={img} className="main-img-src" />
          {status !== "Completed" && (
            <>
              <img src={editIcon} className="edit-icon" onClick={handleEdit} />
              <img
                src={delIcon}
                className="del-icon"
                onClick={() => setIsDelete(true)}
              />
            </>
          )}

          {details?.length > 1 && (
            <>
              <div className="prev-arrow">
                <img
                  src={prevIcon}
                  onClick={() => {
                    setId((id - 1 + details?.length) % details?.length);
                    setImg("");
                  }}
                />
              </div>
              <div className="next-arrow">
                <img
                  src={nextIcon}
                  onClick={() => {
                    setId((id + 1) % details?.length);
                    setImg("");
                  }}
                />
              </div>
            </>
          )}
          {details?.length > 1 && (
            <>
              <div className="dot-img">
                {details?.map((e, idx) => {
                  return idx !== id ? (
                    <i class="bx bx-radio-circle fs-4"></i>
                  ) : (
                    <i class="bx bxs-circle text-danger fs-5"></i>
                  );
                })}
              </div>
              <div className="text-light total-img">
                {id + 1} / {details?.length}
              </div>
            </>
          )}
          <div className="comp-name">
            Component : <span className="fs-20"> {componentName}</span>
          </div>
          <div
            className={`${
              details[id]?.componentStatus === "Low Risk"
                ? "light-green"
                : details[id]?.componentStatus === "High Risk"
                ? "color-danger"
                : details[id]?.componentStatus === "Medium Risk"
                ? "light-orange"
                : "dark-green"
            } comp-status`}
          >
            {details[id]?.componentStatus}
          </div>
        </div>
        <div className="p-3">
          <Row>
            <Col>
              <div className="color-grayl">
                Observation :{" "}
                <span className="text-dark fw-500">
                  {details[id]?.observation}
                </span>
              </div>
            </Col>
            <Col>
              <div className="color-grayl">
                Location :{" "}
                <span className="text-dark fw-500">
                  {details[id]?.location}
                </span>
              </div>
            </Col>
          </Row>
          <Row>
            <Col className="">
              <div className="color-grayl wrap-text">
                Comments :{" "}
                <span className="text-dark fw-500" id={`comment_${did}`}>
                  {details[id]?.towerComponentDetails[0]?.comments}
                </span>
                {Tooltip(
                  `comment_${did}`,
                  details[id]?.towerComponentDetails[0]?.comments,
                  "top"
                )}
              </div>
            </Col>
            <Col className="">
              <div className="color-grayl wrap-text">
                Recommendation :{" "}
                <span className="text-dark fw-500" id={`recommendation_${did}`}>
                  {details[id]?.towerComponentDetails[0]?.recommendation ? (
                    details[id]?.towerComponentDetails[0]?.recommendation
                  ) : (
                    <span className="ms-1 color-grayl"> -</span>
                  )}
                </span>
                {details[id]?.towerComponentDetails[0]?.recommendation &&
                  Tooltip(
                    `recommendation_${did}`,
                    details[id]?.towerComponentDetails[0]?.recommendation,
                    "top"
                  )}
              </div>
            </Col>
          </Row>
          <Row className="text-center">
            <Col>
              <Button
                className="w-5 detail-comp"
                onClick={() => {
                  setIsOffcanvas(true);
                }}
              >
                <span style={{ color: "#006FC4" }}> View Details</span>
              </Button>
            </Col>
          </Row>
        </div>
      </Card>
      {isOffcanvas && (
        <Offcanvas
          isOpen={isOffcanvas}
          direction="end"
          toggle={() => setIsOffcanvas(false)}
        >
          <OffcanvasHeader>
            <span className="text-dark">Observation Details</span>
            <i
              className="bx bx-x text-dark fs-2 pointer"
              onClick={() => setIsOffcanvas(false)}
            ></i>
          </OffcanvasHeader>
          <OffcanvasBody>
            <div className="off-img mb-3">
              <img src={img} alt="" />
            </div>
            <Row className="mb-3">
              <Col sm={6} className="color-grayl fs-16">
                Componet :
              </Col>
              <Col sm={6} className="fw-500 fs-16">
                {componentName}
              </Col>
            </Row>
            <Row className="mb-3">
              <Col sm={6} className="color-grayl fs-16">
                Location :
              </Col>
              <Col sm={6} className="fw-500 fs-16">
                {details[id].location}
              </Col>
            </Row>
            <Row className="mb-3">
              <Col sm={6} className="color-grayl fs-16">
                Observation :
              </Col>
              <Col sm={6} className="fw-500 fs-16">
                {details[id].observation}
              </Col>
            </Row>
            {details[id]?.observationData?.partName && (
              <Row className="mb-3">
                <Col sm={6} className="color-grayl fs-16">
                  Part Name :
                </Col>
                <Col sm={6} className="fw-500 fs-16">
                  {details[id]?.observationData?.partName}
                </Col>
              </Row>
            )}
            {details[id]?.observationData?.size && (
              <Row className="mb-3">
                <Col sm={6} className="color-grayl fs-16">
                  Size :
                </Col>
                <Col sm={6} className="fw-500 fs-16">
                  {details[id]?.observationData?.size}
                </Col>
              </Row>
            )}
            {details[id]?.observationData?.vegetationType && (
              <Row className="mb-3">
                <Col sm={6} className="color-grayl fs-16">
                  Type of Vegetation :
                </Col>
                <Col sm={6} className="fw-500 fs-16">
                  {details[id]?.observationData?.vegetationType}
                </Col>
              </Row>
            )}
            {details[id]?.observationData?.height && (
              <Row className="mb-3">
                <Col sm={6} className="color-grayl fs-16">
                  Height :
                </Col>
                <Col sm={6} className="fw-500 fs-16">
                  {details[id]?.observationData?.height}
                </Col>
              </Row>
            )}
            {details[id]?.observationData?.girth && (
              <Row className="mb-3">
                <Col sm={6} className="color-grayl fs-16">
                  Girth :
                </Col>
                <Col sm={6} className="fw-500 fs-16">
                  {details[id]?.observationData?.girth}
                </Col>
              </Row>
            )}
            {details[id]?.observationData?.quantity && (
              <Row className="mb-3">
                <Col sm={6} className="color-grayl fs-16">
                  Quantity :
                </Col>
                <Col sm={6} className="fw-500 fs-16">
                  {details[id]?.observationData?.quantity}
                </Col>
              </Row>
            )}
            {details[id]?.observationData?.jumpToTower && (
              <Row className="mb-3">
                <Col sm={6} className="color-grayl fs-16">
                  Jumper to Jumper :
                </Col>
                <Col sm={6} className="fw-500 fs-16">
                  {details[id]?.observationData?.jumpToTower}
                </Col>
              </Row>
            )}
            {details[id]?.observationData?.phaseToPhase && (
              <Row className="mb-3">
                <Col sm={6} className="color-grayl fs-16">
                  Phase to Phase :
                </Col>
                <Col sm={6} className="fw-500 fs-16">
                  {details[id]?.observationData?.phaseToPhase}
                </Col>
              </Row>
            )}
            {details[id]?.observationData?.groundClearence && (
              <Row className="mb-3">
                <Col sm={6} className="color-grayl fs-16">
                  Ground Clearence :
                </Col>
                <Col sm={6} className="fw-500 fs-16">
                  {details[id]?.observationData?.groundClearence}
                </Col>
              </Row>
            )}
            {details[id]?.observationData?.overHead && (
              <Row className="mb-3">
                <Col sm={6} className="color-grayl fs-16">
                  Overhead/Underneath Crossing :
                </Col>
                <Col sm={6} className="fw-500 fs-16">
                  {details[id]?.observationData?.overHead}
                </Col>
              </Row>
            )}
            {details[id]?.observationData?.insulatorsCondition && (
              <Row className="mb-3">
                <Col sm={6} className="color-grayl fs-16">
                  Insulators Condition :
                </Col>
                <Col sm={6} className="fw-500 fs-16">
                  {details[id]?.observationData?.insulatorsCondition}
                </Col>
              </Row>
            )}
            {details[id]?.observationData?.testing && (
              <Row className="mb-3">
                <Col sm={6} className="color-grayl fs-16">
                  Testing :
                </Col>
                <Col sm={6} className="fw-500 fs-16">
                  {details[id]?.observationData?.testing}
                </Col>
              </Row>
            )}
            {details[id]?.observationData?.observationName && (
              <Row className="mb-3">
                <Col sm={6} className="color-grayl fs-16">
                  Observation Name :
                </Col>
                <Col sm={6} className="fw-500 fs-16">
                  {details[id]?.observationData?.observationName}
                </Col>
              </Row>
            )}
            {details[id]?.observationData?.legA && (
              <Row className="mb-3">
                <Col sm={6} className="color-grayl fs-16">
                  Leg A :
                </Col>
                <Col sm={6} className="fw-500 fs-16">
                  {details[id]?.observationData?.legA}
                </Col>
              </Row>
            )}
            {details[id]?.observationData?.legB && (
              <Row className="mb-3">
                <Col sm={6} className="color-grayl fs-16">
                  Leg B :
                </Col>
                <Col sm={6} className="fw-500 fs-16">
                  {details[id]?.observationData?.legB}
                </Col>
              </Row>
            )}
            {details[id]?.observationData?.legC && (
              <Row className="mb-3">
                <Col sm={6} className="color-grayl fs-16">
                  Leg C :
                </Col>
                <Col sm={6} className="fw-500 fs-16">
                  {details[id]?.observationData?.legC}
                </Col>
              </Row>
            )}
            {details[id]?.observationData?.legD && (
              <Row className="mb-3">
                <Col sm={6} className="color-grayl fs-16">
                  Leg D :
                </Col>
                <Col sm={6} className="fw-500 fs-16">
                  {details[id]?.observationData?.legD}
                </Col>
              </Row>
            )}
            <Row className="mb-3">
              <Col sm={6} className="color-grayl fs-16">
                Severity :
              </Col>
              <Col sm={6} className="fw-500 fs-16">
                {details[id].componentStatus}
              </Col>
            </Row>
            <Row className="mb-3">
              <Col sm={6} className="color-grayl fs-16">
                Comment :
              </Col>
              <Col sm={6} className="fw-500 fs-16">
                {details[id]?.comments}
              </Col>
            </Row>
            <Row className="mb-3">
              <Col sm={6} className="color-grayl fs-16">
                Recommendation :
              </Col>
              <Col sm={6} className="fw-500 fs-16">
                {details[id]?.towerComponentDetails[0]?.recommendation}
              </Col>
            </Row>
            <Row className="mb-3">
              <Col sm={6} className="color-grayl fs-16">
                Modified By :
              </Col>
              <Col sm={6} className="fw-500 fs-16">
                {details[id]?.createdBy}
                {console.log(details[id]?.createdBy)}
              </Col>
            </Row>
          </OffcanvasBody>
        </Offcanvas>
      )}

      {isModal && (
        <MarkImageModal
          isModal={isModal}
          records={[]}
          image={currentImage}
          setIsModal={setIsModal}
          getComponents={getComponents}
        />
      )}
    </div>
  );
};

export default ComponentCard;

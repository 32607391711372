import * as api from "./api";
const login = (userName: string, password: string) => {
  var url = "login"; //signin
  var headers = makeHeaders(userName, password);
  return api
    .loginReq(url, {}, headers)
    .then((data) => {
      return data.data;
    })
    .catch((err) => {
      // return err;
      return err?.response?.data;
    });
};

const makeHeaders = (userName: string, password: string) => {
  return {
    Authorization: "Basic " + window.btoa(userName + ":" + password),
  };
};

export default login;

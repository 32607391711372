import React, { useEffect, useState } from "react";

import drawimg from "../../assets/svg-new/draw.svg";
import exportimg from "../../assets/svg-new/export.svg";
import printimg from "../../assets/svg-new/print.svg";
import identifierimg from "../../assets/svg-new/identifier.svg";
import profileimg from "../../assets/svg-new/profile.svg";
import videoimg from "../../assets/svg-new/video.svg";
import pointimg from "../../assets/svg-new/point.svg";
import rotateimg from "../../assets/svg-new/rotate.svg";
import compareimg from "../../assets/svg-new/compare.svg";
import bufferimg from "../../assets/svg-new/buffer.svg";
import polyimg from "../../assets/svg-new/pentagon.svg";
import lineimg from "../../assets/svg-new/Path 24856.svg";
import volImg from "../../assets/svg-new/volImg.svg";
import img3d from "../../assets/icons/g1908.svg";
import siteImg from "../../assets/svg-new/siteicon.svg";
import formimg from "../../assets/svg-new/formicon.svg";
import defaultPointer from "../../assets/svg-new/11 Hand pointing.svg";
import {
  Tooltip,
  checkStringInArray,
  createSelectOption,
  vectorData,
} from "../Components/Utils";
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Col,
  Dropdown,
  DropdownMenu,
  DropdownToggle,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
} from "reactstrap";
import { getVideos } from "../../api/dashboard.api";
import VideoModal from "./VideoModal";
import Draggle from "react-draggable";
import * as api from "../../api/global.api";
import VideoPlayerNew from "../../components/VideoPlayer/ReactPlayer2";
import { ToastContainer, toast } from "react-toastify";
import ScreenshotModal from "./ScreenshotModal";
import { Link, useNavigate } from "react-router-dom";
import { useAppDispatch } from "../../store/hooks";
import { Survey } from "../../store/comparereducer";
import Select from "react-select";
import Dropzone from "react-dropzone";
import { calculateVolume } from "../../api/api";
import { transform } from "ol/proj";
import zip from "../../assets/zip.png";
import { generateXLS } from "../../api/map.api";
import SurveyForm from "./SurveyForm";
import { useSelector } from "react-redux";

const RightPanal = (props) => {
  const user = useSelector((state) => state?.user);
  const { features } = user;
  const history = useNavigate();
  const dispatch = useAppDispatch();
  const [drawTool, setDrawTool] = useState(false);
  const [isVol, setIsVol] = useState(false);
  const [dynaicVol, setDynamicVol] = useState(false);
  const [uploadVol, setUploadcVol] = useState(false);
  const [isOffcanvas, setIsOffcanvas] = useState(false);
  const [isScreenshot, setIsScreenshot] = useState(false);
  const [allData, setAllData] = useState([]);
  const [videos, setVideos] = useState([]);
  const [isVideo, setIsVideo] = useState(false);
  const [drawnLayers, setDrawnLayers] = useState([]);
  const [isSurveyForm, setIsSurveyForm] = useState(false);
  const [selectedMethod, setSelectedMethod] = useState({
    label: "Draw",
    value: "Draw",
  });
  const [selectedLayer, setSelectedLayer] = useState(null);
  const [selectedCalc, setSelectedCalc] = useState(null);
  const [screenshots, setScreenshot] = useState([]);
  const [videoData, setVideoData] = useState({
    url: "",
    info: {},
    videoName: "",
  });
  const [formState, setFormState] = useState({});
  const [file, setFile] = useState(null);
  const [calcVol, setCalcVol] = useState({ area: "", perimeter: "", vol: "" });
  const [isVolShow, setIsVolShow] = useState(false);
  const [calcOn, setCalcOn] = useState([]);

  const handleVideoClick = async () => {
    // if (!isOffcanvas) {
    //   let id = props?.survey?.id;
    //   let data = await getVideos(id);
    //   setAllData(data?.response?.rows);
    //   let newData = data?.response?.rows?.filter((e) => e.fileType === "video");
    //   let finalData = [];
    //   newData?.map((e) => {
    //     let links = e?.fileLinks?.split("#").filter((e) => e !== "");
    //     links.map((ele) => {
    //       finalData.push({ ...e, furl: ele });
    //     });
    //   });
    //   setVideos(finalData);
    // }
    // setIsOffcanvas(true);

    props?.setIsMedia(true);
  };

  const onUploadScreenshot = async (obj) => {
    props?.setLoading(true);
    api.post("multipleSurveyScreenshotUpload", obj).then(async (res) => {
      if (res?.status?.toLowerCase() === "success") {
        toast.success("Screenshot uploaded successfully");
        setIsVideo(false);
        let id = props?.survey?.id;
        let data = await getVideos(id);
        setAllData(data?.response?.rows);
        let newData = data?.response?.rows?.filter(
          (e) => e.fileType === "video"
        );
        let finalData = [];
        newData?.map((e) => {
          let links = e?.fileLinks?.split("#").filter((e) => e !== "");
          links.map((ele) => {
            finalData.push({ ...e, furl: ele });
          });
        });
        setVideos(finalData);
        setIsOffcanvas(true);
        props?.setLoading(false);
      } else {
        props.setLoading(false);
      }
    });
  };

  const deleteScreenshot = (obj) => {
    props.setLoading(true);
    api.post("deleteSurveyScreenshot", obj).then(async (res) => {
      if (res?.status === "Success") {
        toast.success("Screenshot deleted successfully");
        let id = props?.survey?.id;
        let data = await getVideos(id);
        setAllData(data?.response?.rows);
        let ele = data?.response?.rows?.find(
          (e) =>
            e.fileName === obj?.fileName && e.fileType === "survey_screenshot"
        );
        let res = ele?.fileLinks?.split("#");
        res?.pop();
        let result = res?.filter((e) => e !== "");
        setScreenshot({ res: ele, result: result });
        props?.setLoading(false);
      }
    });
  };

  const disableCompare = () => {
    let location = props?.survey?.location;
    let surveylist = props?.allSurvey?.filter(
      (obj) => obj.location[0] == location[0] && obj.location[1] == location[1]
    );
    if (surveylist?.length > 1) return false;
    else return true;
  };

  const onCompareClick = () => {
    dispatch(Survey(props?.allSurvey));
    history("/compares");
  };

  const toggleVol = () => {
    setIsVol(!isVol);
  };

  const handleCalculate = async () => {
    props.setLoading(true);
    let workingOn = props?.raw?.find(
      (e) => e?.layerType === selectedCalc?.value
    );
    let layer = props.dynamicData?.find(
      (e) => e.FeatureName === selectedLayer.value
    );

    let convertedPolygonCoordinates = layer?.Coordinates?.coordinates.map(
      function (ringCoords) {
        return ringCoords.map(function (coord) {
          return transform(coord, "EPSG:3857", "EPSG:4326");
        });
      }
    );
    let obj = {
      polycord: convertedPolygonCoordinates[0],
      workspace_name: workingOn?.sourceName?.split(":").shift(),
      source_name: workingOn?.sourceName?.split(":").pop(),
      epsg: workingOn?.epsg,
    };
    let res = await calculateVolume("volume/coordinates", obj);
    if (res?.data?.status === "success") {
      setCalcVol({
        vol: res?.data?.resp,
        area: layer?.area,
        perimeter: layer?.perimeter,
      });
      setDynamicVol(false);
      setIsVolShow(true);
      props.setLoading(false);
    } else {
      toast.error(res?.data?.Error);
      props.setLoading(false);
    }
  };

  const handleFormChange = (val, name) => {
    setFormState({ ...formState, [name]: val });
  };

  const acceptFile = (files) => {
    if (files?.length > 1) {
      toast.warning("Please Upload only one file");
    } else if (files[0].name.split(".").pop() !== "zip") {
      toast.warning("Please Upload .zip file");
    } else {
      setFile(files[0]);
    }
  };

  const handleCalculate2 = async () => {
    props.setLoading(true);
    let workingOn = props?.raw?.find(
      (e) => e?.layerType === selectedCalc?.value
    );
    let workspace = workingOn.sourceName.split(":");
    const formData = new FormData();
    formData.append("file", file);
    formData.append("elevation_height", formState?.baseHeight || 0);
    formData.append("source_name", workspace[1]);
    formData.append("workspace_name", workspace[0]);
    let res = await calculateVolume("volume", formData);
    if (res?.data?.Error) {
      toast.error(res?.data?.Error);
      props.setLoading(false);
    } else {
      setCalcVol({
        vol: res?.data?.resp,
        area: "",
        perimeter: "",
      });
      setIsVolShow(true);
      setUploadcVol(false);
      props.setLoading(false);
    }
  };

  const exportXLS = async () => {
    let obj = {
      perimeter: calcVol?.perimeter,
      area: calcVol.area,
      volume: calcVol?.vol + "m³",
    };
    let res = await generateXLS(obj);
    if (res?.url) {
      setIsVolShow(false);
      var a = document.createElement("a");
      a.href = res?.url;
      a.download = res?.url?.split("/")?.pop();
      a.target = "_blank";
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    } else {
      toast.error(res.errormessage);
    }
  };

  useEffect(() => {
    let polyShapes = props?.dynamicData?.filter(
      (e) => e?.Coordinates?.type === "Polygon"
    );
    let data = createSelectOption(polyShapes, "FeatureName");
    setDrawnLayers(data);
  }, [props.dynamicData]);

  useEffect(() => {
    if (props?.closeDynamic) {
      setDynamicVol(false);
      props?.setCloseDynamic("false");
    }
  }, [props.closeDynamic]);

  useEffect(() => {
    let opt = props?.raw?.filter(
      (e) => e?.layerType === "DSM" || e?.layerType === "DTM"
    );
    let calcOnOpt = opt?.map((e) => ({
      label: e?.layerType,
      value: e?.layerType,
    }));
    setCalcOn(calcOnOpt);
  }, [props?.raw]);

  const handleUpdateSite = () => {
    props?.setSiteForm(true);
  };
  return (
    <div className="right-panal">
      <ToastContainer />
      <span className="mb-4 fs-12">Tools</span>

      {checkStringInArray("Draw Tool - Mining Survey", features) && (
        <>
          <img
            src={drawimg}
            alt="draw"
            id="draw"
            className="pointer mb-4"
            onClick={() => {
              props?.setAccessory("Draw");
              setDrawTool(true);
              setIsOffcanvas(false);
              setIsScreenshot(false);
              setIsVol(false);
            }}
          />
          {Tooltip("draw", "Draw", "left")}
        </>
      )}
      {drawTool && props?.accessory !== "none" && (
        <Card
          className={`${
            props.accessory == "Draw"
              ? "tooldropdowndraw"
              : props.accessory == "Buffer"
              ? "tooldropdownbuffer"
              : props.accessory == "Profile View"
              ? "tooldropdownprofileview"
              : "d-none"
          } p-0 mb-0 br-0`}
        >
          <CardHeader className="py-1 ps-3  d-flex align-items-center justify-content-between">
            <div className="fs-16 text-mos">Select Tools</div>
            <i
              className="bx bx-x fs-4 pointer"
              onClick={() => {
                setDrawTool(false);
                props?.setTool("none");
                props?.setIsSelect(false);
              }}
            ></i>
          </CardHeader>
          <CardBody className="d-flex align-items-center p-2">
            {props.accessory != "Profile View" && (
              <div>
                <img
                  src={polyimg}
                  alt="polygon"
                  onClick={() => {
                    props?.setTool("Polygon");
                    props?.setIsSelect(false);
                  }}
                  className="mb-2 me-4 ps-3 pointer"
                />
                <div className="fs-12 text-center text-primary text-center">
                  Polygon
                </div>
              </div>
            )}

            <div>
              <img
                src={lineimg}
                alt="LineString"
                onClick={() => {
                  props?.setTool("LineString");
                  props?.setIsSelect(false);
                }}
                className="mb-3 me-4 ps-3 pointer"
              />
              <div className="fs-12 text-primary text-center">Line</div>
            </div>

            {props.accessory != "Profile View" && (
              <div>
                <img
                  src={pointimg}
                  alt="Point"
                  onClick={() => {
                    props?.setTool("Point");
                    props?.setIsSelect(false);
                  }}
                  className="mb-3 me-4 ps-3 pointer"
                />
                <div className="fs-12 text-center text-primary text-center">
                  Point
                </div>
              </div>
            )}

            {props.accessory != "Profile View" && (
              <div>
                <img
                  src={defaultPointer}
                  alt="select"
                  onClick={() => {
                    props?.setTool("none");
                    props?.setIsSelect(true);
                  }}
                  className="mb-2 me-2 ps-2 pointer"
                />
                <div className="fs-12 text-primary text-center">Select</div>
              </div>
            )}
          </CardBody>
        </Card>
      )}

      {/* Identifier */}
      {checkStringInArray("Identifier Tool - Mining Survey", features) && (
        <>
          <img
            src={identifierimg}
            alt="identifier"
            id="identifier"
            className="pointer mb-4"
            onClick={() => {
              props.setAccessory("Identifier");
              setIsOffcanvas(false);
              setIsScreenshot(false);
              setDrawTool(false);
              setIsVol(false);
            }}
          />
          {Tooltip("identifier", "Identifier", "right")}
        </>
      )}

      {/* Export */}
      {checkStringInArray("Export Tool - Mining Survey", features) && (
        <>
          <img
            src={exportimg}
            alt="export"
            id="export"
            className="pointer mb-4"
            onClick={() => {
              props.setAccessory("Export");
              setIsOffcanvas(false);
              setIsScreenshot(false);
              setDrawTool(false);
              setIsVol(false);
            }}
          />
          {Tooltip("export", "Export", "left")}
        </>
      )}
      {/* Video */}
      {checkStringInArray("Display Video - Mining Survey", features) && (
        <>
          <img
            src={videoimg}
            alt="video"
            id="video"
            className="pointer mb-4"
            onClick={() => {
              handleVideoClick();
              setDrawTool(false);
              setIsVol(false);
            }}
          />
          {Tooltip("video", "Display Video", "right")}
        </>
      )}

      {isOffcanvas && (
        <VideoModal
          data={videos}
          isOpen={isOffcanvas}
          setVideoData={setVideoData}
          toggleOffcanvas={() => {
            setIsOffcanvas(false);
          }}
          setIsVideo={setIsVideo}
          allData={allData}
          setScreenshot={setScreenshot}
          setIsScreenshot={setIsScreenshot}
          setIsOffcanvas={setIsOffcanvas}
        />
      )}

      {isVideo && videoData?.url !== "" && (
        <Draggle>
          <div
            style={{
              position: "absolute",
              top: "10px",
              left: "-1350px",
              right: "100px",
              zIndex: 201,
              background: "transparent",
              margin: 0,
              padding: 0,
            }}
          >
            <VideoPlayerNew
              onClose={() => {
                setIsVideo(false);
                setIsOffcanvas(true);
                setVideoData({ url: "", info: {}, videoName: "" });
              }}
              videoData={videoData}
              onUploadScreenshot={onUploadScreenshot}
            />
          </div>
        </Draggle>
      )}

      {isScreenshot && (
        <Draggle>
          <div
            style={{
              position: "absolute",
              top: "10px",
              left: "-1350px",
              right: "100px",
              zIndex: 200,
              background: "#fff",
              margin: "auto",
              padding: "auto",
            }}
          >
            <ScreenshotModal
              closeModal={() => {
                setIsOffcanvas(true);
                setIsScreenshot(false);
              }}
              data={screenshots}
              deleteScreenshot={deleteScreenshot}
            />
          </div>
        </Draggle>
      )}

      {/* Print */}
      {checkStringInArray("Print Tool - Mining Survey", features) && (
        <>
          <img
            src={printimg}
            alt="print"
            id="print"
            className="pointer mb-4"
            onClick={() => {
              window.print();
            }}
          />
          {Tooltip("print", "Print", "left")}
        </>
      )}
      {/* Profile View */}
      {checkStringInArray("Profile View - Mining Survey", features) && (
        <>
          <img
            src={profileimg}
            alt="profileview"
            id="profileview"
            className="pointer mb-4"
            onClick={() => {
              props.setAccessory("Profile View");
              toast.warning("Please use tool on DTM or DSM layer");
              setIsOffcanvas(false);
              setIsScreenshot(false);
              setDrawTool(true);
              setIsVol(false);
            }}
          />
          {Tooltip("profileview", "Profile View", "right")}
        </>
      )}
      {/* Rotate */}
      {checkStringInArray("Rotate Tool - Mining Survey", features) && (
        <>
          <img
            src={rotateimg}
            alt="rotate"
            id="rotate"
            className="pointer mb-4"
            onClick={() => {
              props.setAccessory("Rotate");
              setIsOffcanvas(false);
              setIsScreenshot(false);
              setDrawTool(false);
              setIsVol(false);
            }}
          />
          {Tooltip("rotate", "Rotate", "left")}
        </>
      )}
      {/* Compare */}
      {checkStringInArray("Comparison Tool - Mining Survey", features) && (
        <>
          <div style={{ pointerEvents: disableCompare() ? "none" : "" }}>
            <img
              src={compareimg}
              alt="compare"
              id="compare"
              className="pointer mb-4"
              onClick={() => onCompareClick()}
            />
            {Tooltip("compare", "Compare", "right")}
          </div>
        </>
      )}
      {/* Buffer */}
      {checkStringInArray("Buffer Tool - Mining Survey", features) && (
        <>
          <img
            src={bufferimg}
            alt="buffer"
            id="buffer"
            className="pointer mb-4"
            onClick={() => {
              props.setAccessory("Buffer");
              setIsOffcanvas(false);
              setIsScreenshot(false);
              setDrawTool(true);
              setIsVol(false);
            }}
          />
          {Tooltip("buffer", "Buffer", "right")}
        </>
      )}
      {/* Volumetric */}
      {checkStringInArray("Volumetric Tool - Land Survey", features) && (
        <Dropdown
          className="card-header-dropdown"
          isOpen={isVol}
          toggle={toggleVol}
          direction="start"
        >
          <DropdownToggle
            tag="a"
            className="text-reset dropdown-btn"
            role="button"
          >
            <img
              src={volImg}
              id="volume"
              alt="volume"
              className="mb-4 pointer"
              onClick={() => props.setAccessory("Volumetric")}
            />
            {Tooltip("volume", "Volumetric Measurement", "right")}
          </DropdownToggle>
          <DropdownMenu className="tools-menu-start p-0">
            <Card className="p-0 mb-0 br-0 brd-none">
              <div
                className="text-center p-2 text-primary bold pointer"
                onClick={() => {
                  setDynamicVol(true);
                  setSelectedLayer(null);
                  setSelectedCalc(null);
                  setUploadcVol(false);
                  setIsVol(false);
                }}
              >
                Dynamic
              </div>
              <div
                className="text-center p-2 text-primary bold pointer"
                onClick={() => {
                  setUploadcVol(true);
                  setDynamicVol(false);
                  setSelectedCalc(null);
                  setIsVol(false);
                  setFile(null);
                }}
              >
                Upload
              </div>
            </Card>
          </DropdownMenu>
        </Dropdown>
      )}
      {dynaicVol && (
        <Card className="dynamicvol">
          <CardHeader className="bold">
            <div className="d-flex justify-content-between">
              <span className="bold"> Dynamic (Volumetric Measurement)</span>
              <i
                className="bx bx-x fs-3 me-2 pointer"
                onClick={() => {
                  props.setAccessory("none");
                  props.setTool("none");
                  setDynamicVol(false);
                }}
              ></i>
            </div>
          </CardHeader>
          <CardBody className="p-3">
            <div className="mb-3">
              <Label className="text-muted">Select Method</Label>
              <Select
                options={[
                  { label: "Draw", value: "Draw" },
                  { label: "Drawn Layer", value: "Drawn Layer" },
                ]}
                value={selectedMethod}
                onChange={(val) => {
                  setSelectedMethod(val);
                  setSelectedLayer(null);
                }}
              ></Select>
            </div>
            {selectedMethod?.value === "Draw" && (
              <div>
                <Label className="text-muted">Select Tool</Label>
                <div>
                  <img
                    src={polyimg}
                    alt="draw"
                    className="bg-gray p-2 pointer"
                    onClick={() => props.setTool("Polygon")}
                  />
                </div>
              </div>
            )}
            {selectedMethod?.value === "Drawn Layer" && (
              <>
                <div className="mb-3">
                  <Label className="text-muted">Select Layer</Label>
                  <Select
                    options={drawnLayers}
                    value={selectedLayer}
                    onChange={(val) => setSelectedLayer(val)}
                    menuPlacement="top"
                  ></Select>
                </div>
                <div className="mb-3">
                  <Label className="text-muted">Calculate On</Label>
                  <Select
                    options={calcOn}
                    value={selectedCalc}
                    onChange={(val) => setSelectedCalc(val)}
                    menuPlacement="top"
                  ></Select>
                </div>
              </>
            )}
          </CardBody>
          {selectedMethod?.value === "Drawn Layer" && (
            <CardFooter className="d-flex justify-content-end">
              <Button
                className="me-2 w-sm"
                outline
                color="primary"
                onClick={() => {
                  props.setAccessory("none");
                  props.setTool("none");
                  setDynamicVol(false);
                }}
              >
                Cancel
              </Button>
              <Button
                className="bg-primary w-sm"
                disabled={!selectedLayer || !selectedCalc}
                onClick={() => handleCalculate()}
              >
                Calculate
              </Button>
            </CardFooter>
          )}
        </Card>
      )}
      {uploadVol && (
        <Card className="uploadvol">
          <CardHeader className="bold">
            <div className="d-flex justify-content-between">
              <span className="bold"> Dynamic (Volumetric Measurement)</span>
              <i
                className="bx bx-x fs-3 me-2 pointer"
                onClick={() => {
                  props.setAccessory("none");
                  setUploadcVol(false);
                }}
              ></i>
            </div>
          </CardHeader>
          <CardBody className="p-3">
            <Row>
              <Col>
                <div className="mb-3">
                  <Label className="text-muted">Layer Name</Label>
                  <Input
                    type="text"
                    placeholder="Enter"
                    value={formState?.layerName}
                    onChange={(e) =>
                      handleFormChange(e.target.value, "layerName")
                    }
                  />
                </div>
              </Col>
              {/* <Col>
                <div className="mb-3">
                  <Label className="text-muted">Base Height (M)</Label>
                  <Input
                    type="number"
                    placeholder="Enter (in Meters)"
                    value={formState?.baseHeight}
                    onChange={(e) =>
                      handleFormChange(e.target.value, "baseHeight")
                    }
                  />
                </div>
              </Col> */}
            </Row>
            {file === null ? (
              <Row className="mb-3">
                <Label className="text-muted">
                  Upload <span className="required">*</span>
                </Label>
                <Dropzone onDrop={(files) => acceptFile(files)}>
                  {({ getRootProps, getInputProps }) => (
                    <div className="dropzone dz-clickable form-control p-0">
                      <div className="needsclick" {...getRootProps()}>
                        <input {...getInputProps()} accept="images/*" />
                        <div className="m-2 d-flex align-items-center justify-content-center">
                          <i className="text-muted ri-upload-cloud-2-fill me-3 fs-2" />
                          <span className="text-muted">
                            Drop files here or click to upload
                          </span>
                        </div>
                      </div>
                    </div>
                  )}
                </Dropzone>
              </Row>
            ) : (
              <Row>
                <div className="d-flex align-items-center">
                  <img src={zip} width="15%" className="me-2" />
                  <div className="me-5">{file?.name}</div>
                  <div className="text-end" onClick={() => setFile(null)}>
                    <i className="bx bx-trash pointer fs-2 text-danger"></i>
                  </div>
                </div>
              </Row>
            )}
            <Row>
              <Col xs={6}>
                <div className="mb-3">
                  <Label className="text-muted">
                    SRS (Spatial Reference System)
                  </Label>
                  <Input
                    type="text"
                    placeholder="Enter"
                    value={formState?.srs}
                    onChange={(e) => handleFormChange(e.target.value, "srs")}
                  />
                </div>
              </Col>
              <Col xs={6}>
                <div className="mb-3">
                  <Label className="text-muted">
                    Calculate On <span className="required">*</span>
                  </Label>
                  <Select
                    options={calcOn}
                    value={selectedCalc}
                    onChange={(val) => setSelectedCalc(val)}
                    menuPlacement="top"
                  ></Select>
                </div>
              </Col>
            </Row>
          </CardBody>
          <CardFooter className="d-flex justify-content-end">
            <Button
              className="me-2 w-sm"
              outline
              color="primary"
              onClick={() => {
                props.setAccessory("none");
                setUploadcVol(false);
              }}
            >
              Cancel
            </Button>
            <Button
              className="bg-primary w-sm"
              disabled={file === null || !selectedCalc}
              onClick={() => handleCalculate2()}
            >
              Calculate
            </Button>
          </CardFooter>
        </Card>
      )}

      {isVolShow && (
        <Modal
          isOpen={isVolShow}
          toggle={() => {
            setIsVolShow(false);
          }}
          className="bg-custom-white toolModal1"
          backdrop={false}
        >
          <ModalHeader
            className={`border-bottom p-3 bg-light`}
            toggle={() => {
              setIsVolShow(false);
            }}
          >
            <span className="text-dark text-bold fs-15">
              {"Dynamic (Volumetric Measurement)"}
            </span>
          </ModalHeader>
          <ModalBody>
            <Row className="mt-3">
              <Col>
                <div className="fs-14 text-muted">Total Volume</div>
                <div className="text-dark fw-500 fs-15">{calcVol?.vol} m³</div>
              </Col>
              {calcVol?.area && (
                <Col>
                  <div className="fs-14 text-muted">Total Area</div>
                  <div className="text-dark fw-500 fs-15">{calcVol?.area}</div>
                </Col>
              )}
              {calcVol?.perimeter && (
                <Col>
                  <div className="fs-14 text-muted">Perimeter</div>
                  <div className="text-dark fw-500 fs-15">
                    {calcVol?.perimeter}
                  </div>
                </Col>
              )}
            </Row>
            <Row className="mt-3">
              <Col className="text-center">
                <Button className="bg-primary w-sm" onClick={exportXLS}>
                  Export
                </Button>
              </Col>
            </Row>
          </ModalBody>
        </Modal>
      )}

      {props?.modal3D && (
        <div className="mb-4">
          <Link to="/3dmodal" target="_blank">
            <img
              src={img3d}
              alt=""
              id="dtool"
              onClick={() =>
                localStorage.setItem(
                  "3dsurveydata",
                  JSON.stringify(props.selectedSurvey)
                )
              }
            />
            {Tooltip("dtool", "3D Modal", "left")}
          </Link>
        </div>
      )}

      <div className="mb-4" onClick={handleUpdateSite}>
        <img src={formimg} alt="" id="sitesupdate" className="pointer" />
        {Tooltip("sitesupdate", "Site Update", "left")}
      </div>

      <div onClick={() => setIsSurveyForm(true)}>
        <img src={siteImg} alt="" id="projectForm" className="pointer" />
        {Tooltip("projectForm", "Project Status Form", "left")}
      </div>

      {isSurveyForm && (
        <SurveyForm
          isSurveyForm={isSurveyForm}
          setIsSurveyForm={setIsSurveyForm}
          survey={props?.selectedSurvey}
          project={props?.selectedProject}
        />
      )}
    </div>
  );
};

export default RightPanal;
